@import 'variables.scss';

.App {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.btn {
  outline: none;
  border-width: 2px;
  font-weight: bold;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-control:focus,
.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none;
}

*:disabled {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.shadow-inner {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.emoji {
  display: inline-block !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.bg-background {
  background-color: map-get($map: $theme-colors, $key: 'background');
}

.collapse-toggler {
  .collapse-plus {
    display: none;
  }
  .collapse-dash {
    display: block;
  }
  &.collapsed {
    .collapse-plus {
      display: block;
    }
    .collapse-dash {
      display: none;
    }
  }
}

.intercom-main {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  width: 56px;
  height: 56px;
  z-index: 12;
  transition: background-color 0.15s, transform 0.15s;
  &:hover {
    transform: scale(1.2);
  }
  &-close {
    &.hidden {
      transform: rotate(-180deg);
    }
  }
  &-open {
    &.hidden {
      transform: rotate(180deg);
    }
  }
  &-open,
  &-close {
    transition: transform 0.3s;
    opacity: 1;
    &.hidden {
      opacity: 0;
    }
  }
}

.intercom-link {
  transition: top 0.3s, box-shadow 0.3s, background-color 0.15s,
    transform 0.15s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  right: 0px;
  width: 56px;
  height: 56px;
  z-index: 11;
  &.hidden {
    top: 0 !important;
    box-shadow: none !important;
  }
  &:hover {
    transform: scale(1.2);
  }
  &-phone {
    top: -65px;
    background-color: #03e78b;
    &:hover {
      background-color: darken(#03e78b, 5%);
    }
  }
  &-messenger {
    top: -130px;
    background-color: #1e88e5;
    &:hover {
      background-color: darken(#1e88e5, 5%);
    }
  }
}

.back-to-top {
  transition: top 0.3s, background-color 0.15s, transform 0.15s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  top: 0px;
  right: 0px;
  width: 56px;
  height: 56px;
  z-index: 10;
  &.hidden {
    top: -100px !important;
  }
  &:hover {
    transform: scale(1.2);
  }
}

.postCard {
  width: fit-content;
  height: fit-content;
  display: none;

  &--front,
  &--back {
    width: 317px;
    height: 225px;
    font-size: 9px;
  }

  &--preview {
    z-index: 99;
    left: 0;
    top: 0;
    backdrop-filter: blur(9px);
    background: rgba(0, 0, 0, 0.6);
    @media screen and (hover: hover) and (pointer: fine) and (min-width: 768px) {
      .postCard:hover {
        transform: scale(2.02);
      }
    }
    .postCard {
      display: block;
      transition: transform 0.1s cubic-bezier(0, 0, 0.2, 1);
      @media screen and (min-width: 768px) {
        transform: scale(2);
      }
      perspective: 1000px;
      .postCard--inner {
        transition: transform 0.9s
          cubic-bezier(0.18, 0.89, 0.32, 1.28);
        transform-style: preserve-3d;
      }
      .postCard--front {
        z-index: 1;
        overflow: hidden !important;
        @media screen and (min-width: 768px) {
          position: absolute;
        }
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      .postCard--back {
        @media screen and (min-width: 768px) {
          transform: rotateY(180deg);
        }
      }
    }
    &--back {
      @media screen and (min-width: 768px) {
        .postCard--inner {
          transform: rotateY(180deg);
        }
      }
    }
  }
}

.slide-in {
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28),
    opacity 0.3s;
  &.from-left {
    transform: translateX(-30%) scale(0.95);
  }
  &.from-right {
    transform: translateX(30%) scale(0.95);
  }
  &.from-top {
    transform: translateY(-30%) scale(0.95);
  }
  &.from-bottom {
    transform: translateY(30%) scale(0.95);
  }
  &--active {
    opacity: 1;
    transform: translateY(0%) translateX(0%) scale(1) !important;
  }
}

.rotate-spinner {
  transform: rotate3d(1, 0, 0, 140deg);
}
@media screen and (hover: hover) and (pointer: fine) and (min-width: 768px) {
  .rotate-spinner {
    -webkit-animation: spinner 2s infinite
      cubic-bezier(0.22, 0.61, 0.36, 1);
    animation: spinner 2s infinite cubic-bezier(0.22, 0.61, 0.36, 1);
    animation-play-state: paused;
    -webkit-animation-play-state: paused;
  }
  .rotate-spinner:hover {
    animation-play-state: running;
    -webkit-animation-play-state: running;
  }
}
@keyframes spinner {
  0% {
    transform: rotate3d(1, 0, 0, 140deg) rotate(0deg);
  }
  100% {
    transform: rotate3d(1, 0, 0, 140deg) rotate(-360deg);
  }
}
