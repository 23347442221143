// The list of available variables: https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss

$font-family-base: 'Montserrat';

$theme-colors: (
  'primary': #2d5f9a,
  'background': #f4f4f4,
);

$font-size-base: 0.9rem;
$paragraph-margin-bottom: 1rem;

$body-color: #656565;

$headings-color: #333333;
$headings-font-family: 'Montserrat';
$headings-margin-bottom: 2rem;

$input-btn-font-size: 1.2rem;

$custom-file-text: (
  hu: 'Keresés',
);

@import 'node_modules/bootstrap/scss/bootstrap.scss';
